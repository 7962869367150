@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  margin: 0;
  padding: 0;
  background-color: black;

}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.premium-animation {
  color: #fff;
  border-color: #6fad47;
  animation: blink-animation 1s linear infinite;
  -webkit-animation: blink-animation 1s linear infinite;
  background: linear-gradient(90deg, #2ab12f 50%, #364f37 95%);
}


@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}



.faqs {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 2px;
}

.faqs .faq {
  margin: 15px;
  padding: 10px;
  color: white !important;
  background: #1E1E1E;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 15px;
  padding-right: 10px;
  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 20px;
  height: 40px;
  transition: all 0.2s ease;
  font-size: 20px;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  content: "_";
  
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}








.question-section {
  background: transparent;
  border: 1px solid lightgray;
  border-radius: 8px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  cursor: pointer;
}

.question-align {
  align-items: center;
  display: flex;
  min-height: 10px;
  text-align: left;
}

.question-align h4 {
  margin-left: 8px;
}

.question-styling {
  font-size: 17px;
  width: 100%;
}

.question-icon {
  background: none;
  border: none;
  color: #1b6aae;
  cursor: pointer;
  font-size: 22px;
  margin-left: auto;
  margin-right: 8px;
}

.rotate {
  transform: rotate(45deg);
}


.answer {
  margin-left: 8px;
  margin-right: 8px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease;
  text-align: left;
}
.m-bet-table{
  border-radius: 30px !important;
}

/* Himsashu  */
.m-bet-table td{
  font-weight: 700;
    font-size: 15px;
    text-align: center;
  
  }
  .m-bet-table td:first-child{
    font-size: 13px;
    width: 60%;
    font-weight: 800;
    text-align: start;
    padding-left: 6px;
  }
  /* 
  
  .bet_list_table{
    border-top: 1px solid gray;
  } */
  
  .odd_table_body tr{
  border-radius: 0px 0px 30px 30px;
  }
  .bet_list_header_div{
    border-top: 1px solid gray;
  }
  #checkbox {
    background-color: #2ab12f;
  }
  

  /* profile css  */
  .wallet-slider .wrapper {
    display: table;
}
.wallet-slider .wrapper .gauge {
  font-family: Arial, Helvetica, sans-serif;
  font-display: swap;
  width: 240px;
  height: 128px;
  border-radius: 115px 115px 0 0;
  position: relative;
  overflow: hidden;
  -webkit-transform-style: flat;
  -webkit-transform: translateZ(0px);
}

.wallet-slider .wrapper .gauge .slice-colors .slice-item:nth-child(1) {
  border-bottom-color: #4376cf;
  left: -9px;
  transform: rotate(114deg);
  top: 54px;
  width: 100px;
}

.wallet-slider .wrapper .gauge .slice-colors .st {
  position: absolute;
  bottom: 0;
  width: 100px;
  height: 0;
  border-bottom: 80px solid #000;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
}
.wallet-slider .wrapper .gauge .slice-colors .slice-item:nth-child(2) {
  border-bottom-color: #b2b2b2;
  transform: rotate(136deg);
  transform-origin: right;
  top: 19px;
  left: -90px;
  width: 120px;
}
.wallet-slider .wrapper .gauge .slice-colors .slice-item:nth-child(3) {
  border-bottom-color: #edcb49;
  transform: rotate(186deg);
  transform-origin: right;
  top: -32px;
  left: -6px;
  border-bottom-width: 90px;
  width: 120px;
}

.wallet-slider .wrapper .gauge .slice-colors .slice-item:nth-child(4) {
  border-bottom-color: #d0d0ce;
  transform: rotate(240deg);
  transform-origin: right;
  top: 10px;
  left: 91px;
}
.wallet-slider .wrapper .gauge .needle {
  width: 125px;
  height: 7px;
  position: absolute;
  bottom: 0;
  left: -4px;
  transform-origin: 100% 4px;
  animation: speed 5s;
}

.wallet-slider .wrapper .gauge .needle-blue {
  transform: rotate(37deg);
}

.wallet-slider .wrapper .gauge .needle-blue::after {
  background: linear-gradient(180deg, #4376cf 0%, #7e9ed9 45.89%, #4376cf 100%);
}

.wallet-slider .wrapper .gauge .needle::after {
  z-index: 0;
  content: "";
  display: block;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: linear-gradient(180deg, #4376cf 0%, #7e9ed9 45.89%, #4376cf 100%);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: .1rem solid #fff;
}

.wallet-slider .wrapper .gauge .gauge-center {
  color: #fff;
  width: 86%;
  height: 92%;
  background: #212121;
  border-radius: 115px 115px 0 0;
  position: absolute;
  box-shadow: 0 -13px 15px -10px rgba(0,0,0,.28);
  right: 7%;
  bottom: -7px;
  color: #fff;
  text-align: center;
  float: left;
  font-size: 1.5em;
  padding: 10px 0 0 0;
  margin: 15% 0 0 0;
  -webkit-font-smoothing: antialiased;
}
.v-application .pt-12 {
  padding-top: 48px !important;
}
.v-application .caption {
  font-size: .75rem !important;
  font-weight: 400;
  letter-spacing: .0333333333em !important;
  line-height: 1.25rem;
  font-family: "Roboto", sans-serif !important;
}

.blink{
  animation: pulse 1.5s linear infinite;


}
@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}


.inplay-blink {
  animation-duration: 500ms;
  animation-name: blink-inplay;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}


@keyframes blink-inplay {
  0% {
      color: red;
  }
  50% {
      color: orange;
  }
  100% {
    color: white;
}
}
.dddddd{
  position: fixed !important;
  top: 0 !important;
}

.fffffffff{
  position: static !important;
}

/* .afterFooter{

  lg:pb-0 pb-10
} */

/* .afterFooter {
  padding-bottom: 0px;
}

@media (max-width: 768px) {
  .afterFooter {
    padding-bottom: 40px !important;
  }
} */
